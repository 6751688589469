<template>
  <div class="priceIndex">
    <div class="banner">
      <img src="@/assets/priceindex_img/banner.jpg" alt="" />
    </div>
    <div class="main center">
      <div class="content">
        <el-row class="del-title">
          <el-col>{{ journalismData.title }}</el-col>
        </el-row>
        <el-row class="del-time">
          <el-col :span="6"
            ><span class="btn">{{
              journalismData.articleTypeValue
            }}</span></el-col
          >
          <el-col
            :span="6"
            v-if="journalismData.createTime"
            class="f-hide"
            >{{ journalismData.createTime.replace("T", " ") }}</el-col
          >
          <el-col :span="6" class="scoureLine"
            >来源:
            <p
              class="f-hide"
              v-if="
                journalismData.source &&
                journalismData.source.length < this.hidefont
              "
            >
              {{ journalismData.source }}
            </p>
            <el-tooltip
              class="item"
              effect="dark"
              :content="journalismData.source"
              placement="top-start"
              v-if="
                journalismData.source &&
                journalismData.source.length >= this.hidefont
              "
            >
              <p class="f-hide">
                {{ journalismData.source.substring(0, this.hidefont) + "..." }}
              </p>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-row class="del-content">
          <el-col v-html="journalismData.content">
            <!-- {{ journalismData.content }} -->
          </el-col>
        </el-row>
        <el-row>
          <el-col v-for="(item, index) in journalismData.fileList" :key="index">
            <div class="fileListImg">
              <img
                src="@/assets/priceindex_img/pdf.png"
                alt=""
                v-if="
                  item.storageName.slice(-4) == '.pdf' ||
                  item.storageName.slice(-4) == '.PDF'
                "
              />
              <img
                src="@/assets/priceindex_img/word.png"
                alt=""
                v-if="
                  item.storageName.slice(-4) == '.doc' ||
                  item.storageName.slice(-5) == '.docx'
                "
              />
              <a
                :href="baseUrl + 'third/third/ossGetFile/' + item.storageName"
                target="_blank"
                >{{ item.originalName }}</a
              >
            </div>
          </el-col>
        </el-row>
        <el-row class="del-mian">
          <el-col>
            免责声明:本文来自新闻客户端自媒体，不代表本网站的观点和立场。
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { articleQuery } from "@/api/hy.js";
export default {
  data() {
    return {
      tableData: [],
      journalismData: {
        operateBy: "",
      },
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      articleQuery({
        articleId: this.$route.query.articleId,
        operateBy: null,
        operateType: 1,
        userId: "",
      }).then((ret) => {
        this.journalismData = ret.data;
        console.log(this.journalismData, 'journalismData----')
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
// ::v-deep
.f-hide {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.f-hide2 {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; //行数
  line-clamp: 2;
  -webkit-box-orient: vertical; //盒子中内容竖直排列
}
.f {
  display: flex;
}

.f1 {
  flex: 1;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-col {
  flex-direction: column;
}

.f-ac {
  align-items: center;
}

.f-ae {
  align-items: flex-end;
}

.f-jc {
  justify-content: center;
}

.f-jsb {
  justify-content: space-between;
}

.f-jsa {
  justify-content: space-around;
}
.mr20 {
  margin-right: 20px;
}
.banner {
  width: 100%;
  height: 400px;
  img {
    width: 100%;
    height: 100%;
  }
}
.del-title {
  text-align: center;
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #40474e;
}
.del-time {
  font-size: 12px;
  text-align: end;
  padding: 23px 0;
  margin-bottom: 32px;
  border-bottom: 1px solid #e4e4e4;
}
.del-content {
  font-family: Microsoft YaHei;
  // font-weight: 400;
  color: #40474e;
  min-height: 450px;
  margin: 0;
}
.del-content {
  &::v-deep {
    table,
    .article_con,
    .main_left.fl {
      width: 100% !important;
    }
    .fix {
      display: none;
    }
  }
}
::v-deep .del-content {
  font-size: 16px;
  .el-col {
    p {
      // text-indent: 2em;
      width: 100%;
      // font-size: 16px;
    }
    p .ql-align-center {
      text-indent: none !important;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    img {
      text-indent: none !important;
      max-width: 100%;
      // width: 100%;
      display: block;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      // float: left;
    }
    // .ql-align-right{
    //   text-align: right;
    // }
  }
}
::v-deep .ql-align-center {
  text-align: center;
}
::v-deep .w1000 {
  width: 100% !important;
}
.del-mian {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  border-bottom: 1px solid #e4e4e4;
  text-align: center;
  margin-bottom: 15px;
  padding: 25px;
}
.del-toke {
  font-size: 12px;
  font-family: PingFang SC;
  color: #999999;
}
.del-pinglun {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.hotTitle {
  cursor: pointer;
  width: 267px;
  overflow: hidden;
  text-overflow: ellipsis; //文本溢出显示省略号
  white-space: nowrap; //文本不会换行
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #40474e;
  flex: 1;
  &::before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #207bc7;
    margin-right: 10px;
    vertical-align: middle;
    margin-bottom: 5px;
  }
}
.star {
  margin: 0 auto;
  font-size: 25px;
  width: 49px;
  height: 49px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 20px;
  }
}
.main{
    background: white;
    padding: 20px;
}
</style>