var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"priceIndex"},[_vm._m(0),_c('div',{staticClass:"main center"},[_c('div',{staticClass:"content"},[_c('el-row',{staticClass:"del-title"},[_c('el-col',[_vm._v(_vm._s(_vm.journalismData.title))])],1),_c('el-row',{staticClass:"del-time"},[_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"btn"},[_vm._v(_vm._s(_vm.journalismData.articleTypeValue))])]),(_vm.journalismData.createTime)?_c('el-col',{staticClass:"f-hide",attrs:{"span":6}},[_vm._v(_vm._s(_vm.journalismData.createTime.replace("T", " ")))]):_vm._e(),_c('el-col',{staticClass:"scoureLine",attrs:{"span":6}},[_vm._v("来源: "),(
              _vm.journalismData.source &&
              _vm.journalismData.source.length < this.hidefont
            )?_c('p',{staticClass:"f-hide"},[_vm._v(" "+_vm._s(_vm.journalismData.source)+" ")]):_vm._e(),(
              _vm.journalismData.source &&
              _vm.journalismData.source.length >= this.hidefont
            )?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.journalismData.source,"placement":"top-start"}},[_c('p',{staticClass:"f-hide"},[_vm._v(" "+_vm._s(_vm.journalismData.source.substring(0, this.hidefont) + "...")+" ")])]):_vm._e()],1)],1),_c('el-row',{staticClass:"del-content"},[_c('el-col',{domProps:{"innerHTML":_vm._s(_vm.journalismData.content)}})],1),_c('el-row',_vm._l((_vm.journalismData.fileList),function(item,index){return _c('el-col',{key:index},[_c('div',{staticClass:"fileListImg"},[(
                item.storageName.slice(-4) == '.pdf' ||
                item.storageName.slice(-4) == '.PDF'
              )?_c('img',{attrs:{"src":require("@/assets/priceindex_img/pdf.png"),"alt":""}}):_vm._e(),(
                item.storageName.slice(-4) == '.doc' ||
                item.storageName.slice(-5) == '.docx'
              )?_c('img',{attrs:{"src":require("@/assets/priceindex_img/word.png"),"alt":""}}):_vm._e(),_c('a',{attrs:{"href":_vm.baseUrl + 'third/third/ossGetFile/' + item.storageName,"target":"_blank"}},[_vm._v(_vm._s(item.originalName))])])])}),1),_c('el-row',{staticClass:"del-mian"},[_c('el-col',[_vm._v(" 免责声明:本文来自新闻客户端自媒体，不代表本网站的观点和立场。 ")])],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("@/assets/priceindex_img/banner.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }